<template>
  <section id="queue-filter-wrapper" class="queue-version-filter-wrapper">
    <label for="queue-filter">
      {{
        multipleSelect
          ? lang.metrics.filters.queues.label[languageSelected]
          : lang.metrics.filters.queue.label[languageSelected]
      }}
    </label>

    <multiselect
      :key="`multiselect-${key.getTime()}`"
      v-model="value"
      tag-placeholder=""
      :placeholder="lang.filters.queue.placeholder[languageSelected]"
      :selected-label="lang.filters.queue.selectedLabel[languageSelected]"
      :deselect-label="lang.filters.queue.deselectLabel[languageSelected]"
      label="name"
      track-by="code"
      :options="options"
      :multiple="multipleSelect"
      :taggable="true"
      :limit="3"
      :limit-text="limitText"
      :show-labels="false"
      @input="change"
    />
    <!-- @tag="addTag" -->

    <vs-button
      color="primary"
      type="flat"
      size="small"
      @click="clear"
      class="reset-button mt-1"
    >
      {{ lang.metrics.filters.range.clear[languageSelected] }}
    </vs-button>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import services from '../../../../../services/queues.service'

import staffManagementMixin from '@/mixins/staffManagementMixin'

export default {
  name: 'QueueFilter',
  props: {
    multipleSelect: {
      type: Boolean,
      default: true
    },
    allowEmpty: {
      type: Boolean,
      default: false
    },
    startAll: {
      type: Boolean,
      default: false
    }
  },
  components: {
    Multiselect: () => import('vue-multiselect')
  },
  mixins: [staffManagementMixin],
  data() {
    return {
      isLoading: false,
      key: new Date(),
      queues: [],
      value: [],
      options: []
    }
  },
  computed: {
    ...mapState(['lang', 'languageSelected', 'session', 'useStaffManagement'])
  },
  methods: {
    limitText(count) {
      return `+${count}`
    },
    change() {
      let value
      if (this.multipleSelect) {
        let allQueues = []
        this.options.forEach(q => allQueues.push(q))
        value = this.value.length ? this.value : allQueues
        this.$emit('change', {
          type: 'queue',
          value: value.map(queue => queue.code)
        })
      } else {
        value = this.value

        this.$emit('change', {
          type: 'queue',
          value: value ? [value.code] : null
        })
      }
    },
    async getQueues() {
      this.$vs.loading()
      this.isLoading = true

      const response = await services.getQueues()

      this.queues = response.data;

      // this.hasBots = this.queues.length > 0

      this.queues.forEach(element => {
        this.options.push({
          name: element.name,
          code: element._id,
        })
      })
      this.key = new Date()
      this.$vs.loading.close()
      this.isLoading = true
    },
    reset() {
      this.value = []
    },
    clear() {
      this.reset()
      this.change()
    },
    selectQueue(queueId) {
      this.value = []
      const queue = this.options.find(b => b.code === queueId)
      if (this.multipleSelect) {
        this.value.push(queue)
      } else {
        this.value = queue
      }
    }
  },
  async mounted() {
    await this.getQueues()

    // order queues alphabetically
    this.options.sort((a, b) => {
      if (a.name.toLowerCase() < b.name.toLowerCase()) {
        return -1
      }
      if (a.name.toLowerCase() > b.name.toLowerCase()) {
        return 1
      }
      return 0
    })
    if (this.startAll) {
      this.value = []
      this.options.forEach(opt => {
        this.value.push(opt)
      })
      const queueMap = this.value
      this.$emit('init', {
        type: 'queue',
        value: queueMap.map(queue => queue.code)
      })
    } else if (!this.allowEmpty) {
      this.value = this.options[0]
      const queueMap = this.options[0]
      this.$emit('init', {
        type: 'queue',
        value: [queueMap[0]].map(queue => queue.code)
      })
    }
  }
}
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style lang="scss" scoped>
#queue-filter-wrapper {
  display: flex;
  flex-direction: column;

  label {
    display: block;
  }
  .vs-input--input {
    min-height: 43px;
  }

  .reset-button {
    align-self: flex-end;
  }
}
.queue-version-filter-wrapper {
  .multiselect__option {
    span {
      padding-left: 20px !important;
    }
  }

  .multiselect__option--group {
    span {
      padding-left: 0px !important;
    }
  }
}

.multiselect__tags {
  min-height: 43px;
  max-height: 75px;
  width: 320px;
  overflow-y: auto;
}
.multiselect__tag {
  background: #ff9e43 !important;
}
.multiselect__tag-icon {
  background: #ff9e43 !important;
}
.multiselect__tag-icon:hover {
  background: #ff8102 !important;
}
.multiselect__tag-icon:after {
  color: white !important;
}
</style>
